/* eslint-disable prettier/prettier */
import Login from "@/views/public/auth/Login.vue";
import Signup from "@/views/public/auth/Signup.vue";
import ChangePassword from "@/views/public/auth/ChangePassword.vue";
import ResetPassword from "@/views/public/auth/ResetPassword.vue";
import TwoFactorAuth from "@/views/public/auth/2Factor.vue";
import guest from "@/router/middleware/guest";

export const publicRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { middleware: [guest] },
  },
  // {
  //   path: "/signup",
  //   name: "signup",
  //   component: Signup,
  //   meta: { middleware: [guest] },
  // },
  {
    path: "/2factor-auth",
    name: "2Factor",
    component: TwoFactorAuth,
    meta: { middleware: [guest] },
  },
  {
    path: "/changepassword",
    name: "changePassword",
    component: ChangePassword,
    meta: { middleware: [guest] },
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { middleware: [guest] },
  },
];
