<!-- eslint-disable prettier/prettier -->
<template>
  <div class="bg min-h-screen flex flex-wrap items-center">
    <div class="w-full md:w-2/5 mx-auto mb-4 py-8">
      <div class="w-full px-2 md:w-10/12 md:mx-auto">
        <div class="flex items-center justify-center mb-4">
          <img
            alt="Kredi logo"
            src="@/assets/kredi_logo.png"
            class="w-32 object-cover"
          />
        </div>
        <div class="bg-white shadow-md rounded-md px-8 py-10">
          <div class="flex justify-center gap-x-3 mb-5 text-gray-400">
            <p
              class="text-sm"
              :class="{ 'text-orange-500 font-semibold': signUp }"
            >
              Register
            </p>
          </div>
          <div class="">
            <div class="flex flex-wrap md:flex-nowrap gap-x-2 mb-4">
              <div class="w-full md:w-1/2 mb-2">
                <label
                  for=""
                  class="text-xs mb-2 uppercase text-gray-400 font-medium"
                  >First name</label
                >
                <input
                  type="text"
                  class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
                  placeholder="First name"
                  v-model="form.first_name"
                />
              </div>
              <div class="w-full md:w-1/2 mb-2">
                <label
                  for=""
                  class="text-xs mb-2 uppercase text-gray-400 font-medium"
                  >last name</label
                >
                <input
                  type="text"
                  class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
                  placeholder="Last name"
                  v-model="form.last_name"
                />
              </div>
            </div>
            <div class="flex flex-wrap md:flex-nowrap gap-x-2 mb-4">
              <div class="w-full md:w-1/2 mb-2">
                <label
                  for=""
                  class="text-xs mb-2 uppercase text-gray-400 font-medium"
                  >Email</label
                >
                <input
                  type="email"
                  class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
                  placeholder="Enter email"
                  v-model="form.email"
                />
              </div>
              <div class="w-full md:w-1/2 mb-2">
                <label
                  for=""
                  class="text-xs mb-2 uppercase text-gray-400 font-medium"
                  >Phone</label
                >
                <input
                  type="text"
                  class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
                  placeholder="08073234322"
                  v-model="form.phone"
                />
              </div>
            </div>

            <div class="flex flex-wrap md:flex-nowrap gap-x-2 mb-4">
              <div class="w-full md:w-1/2 mb-2">
                <label
                  for=""
                  class="text-xs mb-2 uppercase text-gray-400 font-medium"
                  >Password</label
                >
                <input
                  type="password"
                  class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
                  placeholder="********"
                  v-model="form.password"
                />
              </div>
              <div class="w-full md:w-1/2 mb-2">
                <label
                  for=""
                  class="text-xs mb-2 uppercase text-gray-400 font-medium"
                  >confirm password</label
                >
                <input
                  type="password"
                  class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
                  placeholder="********"
                  v-model="form.password_confirmation"
                />
              </div>
            </div>
            <div class="w-full mb-8" v-if="form.invitation_code">
              <label
                for=""
                class="text-xs mb-2 uppercase text-gray-400 font-medium"
                >Designation</label
              >
              <input
                type="text"
                class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
                placeholder=""
                v-model="form.designation"
              />
            </div>
            <p class="text-xs text-gray-500 text-center">
              By signing up you agree to the Terms of Service and Privacy Policy
            </p>
            <button
              :disabled="validateInput"
              class="bg-orange-600 py-4 w-full rounded-md disabled:opacity-60 text-white text-sm my-3"
              @click="createAccount"
              v-if="token"
            >
              {{ loading ? "Please wait ..." : "Signup" }}
            </button>
            <button
              :disabled="validateInput"
              class="bg-blue-600 py-4 w-full rounded-md disabled:opacity-60 text-white text-sm my-3"
              @click="recaptcha"
              v-else
            >
              {{ loading ? "Please wait ..." : "Verify recaptcha" }}
            </button>
          </div>
          <p class="text-sm text-gray-500 text-center">
            Already have an account?
            <router-link to="/login" class="text-blue-500 cursor-pointer"
              >Login here</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { register } from "@/services";
import { useRouter, useRoute } from "vue-router";
import { useReCaptcha } from "vue-recaptcha-v3";
// import CryptoJS from "crypto-js/";

export default defineComponent({
  name: "SignupView",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const form = ref({
      email: "",
      password: "",
      confirm_password: "",
      designation: "",
      nuban: "",
      phone: "",
      first_name: "",
      middle_name: "",
      last_name: "",
    });

    const signUp = ref(false);
    const otp = ref(false);
    const loading = ref(false);
    const token = ref("");

    const handleOnChange = (e) => {
      form.value.otp = e;
    };

    onMounted(() => {
      if (route.query?.c) {
        signUp.value = true;
        form.value.invitation_code = route.query.c;
      }
    });

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      loading.value = true;
      await recaptchaLoaded();

      token.value = await executeRecaptcha("login");
      loading.value = false;
    };

    // async function verifyAccount() {
    //   loading.value = true;
    //   try {
    //     const res = await verifyNuban({
    //       nuban: form.value.nuban,
    //       otp: form.value.otp,
    //     });
    //     this.$toast.success(res.message);

    //     if (res.data.firstName || res.data.lastName) {
    //       form.value = {
    //         email: res.data.email,
    //         first_name: res.data.firstName,
    //         last_name: res.data.lastName,
    //         middle_name: res.data.middleName,
    //         phone: res.data.phoneNo,
    //         nuban: res.data.nuban,
    //       };
    //       signUp.value = true;
    //     } else {
    //       otp.value = true;
    //     }
    //   } catch (err) {
    //     this.$toast.error(err.response.data?.message || "Network Error");
    //   }
    //   loading.value = false;
    // }

    async function createAccount() {
      loading.value = true;
      try {
        const res = await register({
          ...form.value,
        });

        this.$toast.success(res.message);
        router.push({
          path: "/login",
        });
      } catch (err) {
        this.$toast.error(
          err.response.response?.data?.message || "Network Error"
        );
      }
      loading.value = false;
    }

    return {
      form,
      // verifyAccount,
      loading,
      otp,
      handleOnChange,
      createAccount,
      validateInput: computed(
        () =>
          loading.value ||
          !form.value.email ||
          !(
            form.value.password &&
            form.value.password === form.value.password_confirmation
          ) ||
          !form.value.phone
      ),
      checkInput: computed(() => !form.value.nuban && !form.value.otp),
      signUp,
      recaptcha,
      token,
      inv: route.query?.c,
    };
  },
});
</script>

<style>
.bg {
  background-color: #f4f4f4;
}
</style>
