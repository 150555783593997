import { createApp } from "vue";
import "remixicon/fonts/remixicon.css";
import VOtpInput from "vue3-otp-input";
import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net";
import Toaster from "@meforma/vue-toaster";
import VueCryptojs from "vue-cryptojs";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";
import "vue-select/dist/vue-select.css";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

DataTable.use(DataTablesLib);
createApp(App)
  .use(store)
  .use(router)
  .use(VueCryptojs)
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })
  .use(Toaster, {
    position: "top-right",
  })
  .component("v-otp-input", VOtpInput)
  .component("v-select", vSelect)
  .component("VueDatePicker", VueDatePicker)
  // .component("v-datepicker", Datepicker)
  .mount("#app");
