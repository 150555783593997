import { request } from "../../utils";
// import { decrypted } from "@/helpers/algo";

export const changePin = async ({ pin, pin_confirmation, otp }) => {
  const { data } = await request.post(`/change-pin`, {
    pin,
    pin_confirmation,
    otp,
  });
  return data;
};

export const changePassword = async ({
  old_password,
  password,
  password_confirmation,
  otp,
}) => {
  const { data } = await request.post(`/user/change-password`, {
    old_password,
    password,
    password_confirmation,
    otp,
  });
  return data;
};

export const getSettings = async () => {
  const { data } = await request.get(`/settings`);
  return data;
};

export const getUserActivities = async ({ page }) => {
  const { data } = await request.get(`/my-activities?page=${page}`);
  return data;
};

export const getLoggedInUser = async () => {
  const { data } = await request.get(`/user`);
  return data;
};

export const getMerchant = async () => {
  const { data } = await request.get(`/merchant`);
  return data;
};

export const getSettingsByKey = async (business_uid) => {
  const { data } = await request.get(`/settings/partner/${business_uid}`);
  return data.data;
};

export const generateToken = async (partner_id) => {
  const { data } = await request.post(`/generate-token`, {
    partner_id,
  });
  return data.data;
};

export const updateSettings = async (value) => {
  const { data } = await request.put(`/settings`, value);
  return data;
};

export const updateWebhook = async ({
  webhook_url,
  partner_id,
  secret_key,
}) => {
  const { data } = await request.put(`/partners/webhook`, {
    webhook_url,
    partner_id,
    secret_key,
  });
  return data;
};

export const updateProfile = async (value) => {
  const { data } = await request.put(`/user/update`, value);
  return data;
};

export const sendOTP = async () => {
  const { data } = await request.post(`/user/send-otp`);
  return data;
};
