<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppView",
});
</script>

<style lang="scss">
body {
  font-family: "Inter", sans-serif;
}
</style>
