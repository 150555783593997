<template>
  <Layout>
    <div class="mb-2">
      <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
        >Password</label
      >
      <input
        type="password"
        class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
        placeholder="********"
        v-model="form.password"
      />
    </div>

    <div class="mb-2">
      <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
        >Confirm Password</label
      >
      <input
        type="password"
        class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
        placeholder="********"
        v-model="form.password_confirmation"
      />
    </div>

    <button
      @click="handleChangePassword"
      :disabled="validateInput"
      class="bg-blue-600 py-4 w-full block text-center disabled:opacity-60 rounded-md text-white text-sm my-3"
    >
      {{ loading ? "Please wait..." : "Change Password" }}
    </button>
    <p class="text-sm text-gray-500 text-center">
      Back to
      <router-link to="/login" class="text-blue-500 cursor-pointer">
        login
      </router-link>
    </p>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";

import { defineComponent, ref, computed } from "vue";
import { resetPassword } from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ChangePasswordView",
  components: { Layout },
  setup() {
    const router = useRouter();
    const form = ref({
      password: "",
      password_confirmation: "",
    });

    const loading = ref(false);

    async function handleChangePassword() {
      loading.value = true;
      try {
        const res = await resetPassword({
          ...form.value,
          reset_token: this.$route.query.tk,
          uuid: this.$route.query.i,
        });
        router.push({
          path: "login",
        });
        this.$toast.success(res.message);
      } catch (err) {
        this.$toast.error(
          err.response?.response?.data?.message || "Network Error"
        );
      }
      loading.value = false;
    }
    return {
      form,
      handleChangePassword,
      validateInput: computed(
        () =>
          !form.value.password ||
          !form.value.password_confirmation ||
          loading.value
      ),
      loading,
    };
  },
});
</script>
