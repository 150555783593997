import axios from "axios";
// import { encrypted, decrypted } from "@/helpers/algo";
// import { getActiveBusiness } from "@/helpers/auth";

window.axios = axios;
// import { clearStorage } from "@/helpers/auth";
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.interceptors.response.use((response) => response.data);
//Default setup for Authorization
const token = JSON.parse(localStorage.getItem("KB_token"));

const options = {
  baseURL: process.env.VUE_APP_API_BASEURL,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    "access-key": process.env.VUE_APP_API_KEY,
    Authorization: token ? `Bearer ${token}` : "",
  },
};

export const request = axios.create(options);

request.interceptors.request.use(
  (request) => {
    // if (
    //   request.method === "post" ||
    //   request.method === "put" ||
    //   request.method === "patch"
    // ) {
    //   request.data =
    //     request.url === "/send-otp" ? null : { data: encrypted(request.data) };
    // }

    return request;
  }
  // (error) => {}
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      const errorData = error?.response?.data;

      if (errorData?.message === "Unauthenticated.") {
        // clearStorage();
      }

      return Promise.reject({
        response: error,
        // response: { data: { ...decrypted(errorData.data) } },
      });
    } catch (error) {
      return Promise.reject(error);
      // return Promise.reject(decrypted(error));
    }
  }
);
