import { request } from "../../utils";
// import { decrypted } from "@/helpers/algo";

export const getWallet = async () => {
  const { data } = await request.get(`/balance`);
  return data.data;
};

export const getTerminals = async (page) => {
  const { data } = await request.get(`/my-terminals?page=${page}`);
  return data.data;
};

export const requestTerminals = async (merchantId) => {
  const { data } = await request.post(`/create-terminal/${merchantId}`);
  return data.data;
};

export const showWalletCustomers = async ({ partner_id, uid }) => {
  const { data } = await request.get(`/partner/${partner_id}/customers/${uid}`);
  return data.data;
};

export const getAccountStatement = async () => {
  const { data } = await request.get(`/account-statement`);
  return data.data;
};

export const exportAccountStatement = async () => {
  const { data } = await request.get(`/account-statement/send`);
  return data.data;
};
