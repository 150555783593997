<!-- eslint-disable prettier/prettier -->
<template>
  <Layout>
    <div class="mb-4">
      <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
        >Email</label
      >
      <input
        type="email"
        class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
        placeholder="Enter email"
        v-model="form.email"
      />
    </div>
    <div class="mb-2">
      <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
        >Password</label
      >
      <input
        type="password"
        class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
        placeholder="********"
        v-model="form.password"
        @keypress.enter="signIn"
      />
    </div>

    <router-link
      to="/resetPassword"
      class="text-xs text-blue-500 cursor-pointer mb-8"
    >
      Forgot password?
    </router-link>
    <button
      :disabled="validateInput"
      class="bg-blue-600 py-4 w-full block text-center disabled:opacity-60 rounded-md text-white text-sm my-3"
      @click="signIn"
    >
      {{ loading ? "Please wait..." : "Login" }}
    </button>
    <p class="text-sm text-gray-500 text-center hidden">
      New here?
      <router-link to="/signup" class="text-blue-500 cursor-pointer">
        Create account
      </router-link>
    </p>
  </Layout>
</template>

// eslint-disable-next-line prettier/prettier
<script>
import Layout from "./Layout.vue";
import { login } from "@/services";
import { defineComponent, ref, computed } from "vue";
// import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "LoginView",
  components: { Layout },
  setup() {
    // const router = useRouter();
    const store = useStore();
    const form = ref({
      email: "",
      password: "",
    });

    const loading = ref(false);

    async function signIn() {
      loading.value = true;
      try {
        const res = await login(form.value);
        this.$toast.success(res?.message);
        if (res?.data?.token) {
          store.commit("SAVE_USER", res.data);
          window.location.href = "/overview";
        }
        // else {
        //   store.commit("SAVE_CREDENTIAL", form.value);
        //   router.push({
        //     path: "2factor-auth",
        //   });
        // JP8r6hg40j88398dngsYht1tJ2kehEW3fEe7iUrysjLui859
        // }
      } catch (err) {
        this.$toast.error(
          err.response?.response?.data?.message || "Network Error"
        );
      }
      loading.value = false;
    }

    return {
      form,
      signIn,
      loading,
      validateInput: computed(
        () => !form.value.email || !form.value.password || loading.value
      ),
    };
  },
});
</script>
