import auth from "@/router/middleware/auth";
// import onboard from "@/router/middleware/onboard";

export const protectedRoutes = [
  {
    path: "/",
    name: "Layout",
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/UI/Layouts/DefaultLayout.vue"
      ),
    meta: { middleware: [auth] },
    children: [
      {
        path: "",
        name: "DashboardView",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/protected/home/Index.vue"
          ),
      },
      {
        path: "/account-statement",
        name: "AccountStatementView",
        component: () =>
          import(
            /* webpackChunkName: "account-statement" */ "@/views/protected/home/AccountStatement.vue"
          ),
      },
      {
        path: "/terminal",
        name: "TerminalView",
        component: () =>
          import(
            /* webpackChunkName: "terminal" */ "@/views/protected/terminal/Index.vue"
          ),
      },
      {
        path: "/inward-transfer",
        name: "TransactionView",
        component: () =>
          import(
            /* webpackChunkName: "transaction" */ "@/views/protected/transaction/Index.vue"
          ),
      },
      {
        path: "/outward-transfer",
        name: "OutwardTransactionView",
        component: () =>
          import(
            /* webpackChunkName: "outwardTransaction" */ "@/views/protected/outwardTransfer/Index.vue"
          ),
      },
      {
        path: "/pos-transaction",
        name: "PosTransactionView",
        component: () =>
          import(
            /* webpackChunkName: "posTransaction" */ "@/views/protected/posTransaction/Index.vue"
          ),
      },
      {
        path: "/remittance",
        name: "RemittanceView",
        component: () =>
          import(
            /* webpackChunkName: "remittanceView" */ "@/views/protected/remittance/Index.vue"
          ),
      },
      {
        path: "/settings",
        name: "SettingsView",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/protected/settings/Index.vue"
          ),
      },
      {
        path: "/transfer",
        name: "TransferView",
        component: () =>
          import(
            /* webpackChunkName: "transfer" */ "@/views/protected/transfer/Index.vue"
          ),
      },
    ],
  },
  {
    path: "/overview",
    name: "OverviewPage",
    component: () =>
      import(
        /* webpackChunkName: "switch business" */ "@/views/protected/Overview.vue"
      ),
    meta: { middleware: [auth] },
  },
];
