<template>
  <div class="bg h-screen flex flex-wrap items-center">
    <div class="w-full mx-4 md:mx-auto mb-4 py-8" :class="width">
      <div class="flex items-center justify-center mb-4">
        <img
          alt="Kredi logo"
          src="@/assets/kredi_logo.png"
          class="w-32 object-cover"
        />
      </div>
      <div class="bg-white shadow-md rounded-md px-8 py-16">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutView",
  props: {
    width: {
      type: String,
      default: " md:w-4/12",
    },
  },
};
</script>
