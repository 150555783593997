import { request } from "../../utils";
export * from "./businessTeam";

export const getBusinesses = async () => {
  const { data } = await request.get(`/businesses`);
  return data;
};

export const getBusinessRequest = async ({ page }) => {
  const { data } = await request.get(`/add-business?page=${page}`);
  return data.data;
};

export const getBusinessWebhook = async (partner_id) => {
  const { data } = await request.get(`/partners/${partner_id}/webhook`);
  return data.data;
};

export const createBusiness = async ({
  organisation_name,
  phone_no,
  email,
  address,
  first_name,
  last_name,
  organisation_code,
  bvn,
  dob,
  phone_two,
  state,
  lga,
  city,
  customer_document,
  rc_number,
  document_type,
  // signature,
  designation,
  country,
}) => {
  const { data } = await request.post(`/create-merchant`, {
    organisation_name,
    phone_no,
    email,
    address,
    first_name,
    last_name,
    organisation_code,
    bvn,
    dob,
    phone_two,
    state,
    lga,
    city,
    signature: null,
    designation,
    customer_document,
    rc_number,
    document_type,
    country,
  });
  return data;
};

export const deleteBusiness = async (id) => {
  const { data } = await request.delete(`/add-business/${id}/delete`);
  return data;
};
