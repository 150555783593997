import { getActiveBusiness } from "@/helpers/auth";

const state = {
  activaBusinessId: getActiveBusiness || "",
  dispute: {},
};

const getters = {};

const mutations = {
  SAVE_DISPUTE(state, data) {
    state.dispute = data;
  },
};

const actions = {};

export default {
  namedspaced: true,
  state,
  getters,
  mutations,
  actions,
};
