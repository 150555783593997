import { request } from "../../utils";
// import { decrypted } from "@/helpers/algo";

export const getTransaction = async ({ date_from, date_to, search, page }) => {
  const { data } = await request.get(
    `/transfer-inflow?date_from=${date_from}&date_to=${date_to}&search=${search}&page=${page}`
  );
  return data.data;
};

export const getOutwardTransaction = async ({
  date_from = "2023-05-22",
  page,
}) => {
  const { data } = await request.get(
    `/transfer-inflow?date_from=${date_from}&page=${page}`
  );
  return data.data;
};

export const getPosTransaction = async ({
  date_from,
  date_to,
  search,
  page,
}) => {
  const { data } = await request.get(
    `/payments?date_from=${date_from}&date_to=${date_to}&search=${search}&page=${page}`
  );
  return data.data;
};

export const getOutflowTransaction = async ({
  date_from,
  date_to,
  search,
  page,
}) => {
  const { data } = await request.get(
    `/outflow?date_from=${date_from}&date_to=${date_to}&search=${search}&page=${page}`
  );
  return data.data;
};

export const getRemittanceTransaction = async ({
  date_from = "2023-05-22",
}) => {
  const { data } = await request.get(`/remittances?date_from=${date_from}`);
  return data.data;
};

// export const getTransactionChart = async (business_uid) => {
//   const { data } = await request.get(`/transactions/${business_uid}/chart`);
//   return data.data;
// };

export const getBankList = async () => {
  const { data } = await request.get(`/banks`);
  return data.data;
};

export const getAccountEnquiry = async ({ account_no, bank_code }) => {
  const { data } = await request.post(`/beneficiary-enquiry`, {
    beneficiaryBankCode: bank_code,
    beneficiaryAccountNumber: account_no,
  });
  return data.data;
};

export const transferFund = async ({
  transaction_reference,
  transaction_amount,
  beneficiary_account_no,
  beneficiary_account_name,
  beneficiary_bvn,
  beneficiary_entity_code,
  narration,
  pin,
  otp,
}) => {
  const { data } = await request.post(`/transfer`, {
    transaction_reference,
    transaction_amount,
    beneficiary_account_no,
    beneficiary_account_name,
    beneficiary_bvn,
    beneficiary_entity_code,
    narration,
    pin,
    otp,
  });
  return data;
};
