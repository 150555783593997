import { request } from "../../utils";
// import { decrypted } from "@/helpers/algo";

export const login = async (values) => {
  const { data } = await request.post(`/login`, values);
  return data;
};

export const register = async (values) => {
  const { data } = await request.post(`/register`, values);
  return data;
};

export const verifyOtp = async (values) => {
  const { data } = await request.post(`/verify-otp`, values);
  return data;
};

// export const verifyNuban = async ({ nuban, otp }) => {
//   const { data } = await request.post(`/verify-nuban`, { nuban, otp });
//   return decrypted(data.data);
// };

export const forgotPassword = async ({ email }) => {
  const { data } = await request.post(`/forgot-password`, { email });
  return data;
};

export const resetPassword = async ({
  reset_token,
  uuid,
  password,
  password_confirmation,
}) => {
  const { data } = await request.post(`/reset-password`, {
    reset_token,
    uuid,
    password,
    password_confirmation,
  });
  return data;
};

export const logout = async () => {
  const { data } = await request.post(`/user/logout`);
  return data;
};
