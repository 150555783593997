// import { clearStorage } from "@/helper/auth";
// import { login } from "../../services/auth/auth";

const state = {
  token: JSON.parse(localStorage.getItem("KB_token")) || "",
  logoutTime: JSON.parse(localStorage.getItem("logout_last_time")) || "",
  merchant: JSON.parse(localStorage.getItem("KB_merchant")) || {},
  form: {},
};

const getters = {};

const mutations = {
  SAVE_USER(state, details) {
    state.token = details.token;
    localStorage.setItem("KB_token", JSON.stringify(details.token));
  },
  SAVE_CREDENTIAL(state, details) {
    state.form = details;
  },
  SAVE_MERCHANT(state, details) {
    state.merchant = details;
    localStorage.setItem("KB_merchant", JSON.stringify(details));
  },
  LOGOUT(state) {
    state.token = "";
    localStorage.clear();
    window.location.href = "/login";
  },
};

const actions = {};

export default {
  namedspaced: true,
  state,
  getters,
  mutations,
  actions,
};
